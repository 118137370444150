import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMaintenanceDocumentation } from 'pages/CatalogPage/DH/MaintenanceValues/MaintenanceValues';
import { Box, Flex, MarginBox, Pipeline, PrintButtonRound, Text } from 'UI';

const MaintenanceHeader = ({ handlePrint }: { handlePrint: () => void }) => {
  const { t } = useTranslation();
  const menu = useGetMaintenanceDocumentation();
  return (
    <MarginBox my={30}>
      <Flex>
        <Text type={'h1_banner_light'} disableGutter>
          {t('catalog.parts.category.maintenance_plan', 'Maintenance plan')}
        </Text>
        <Flex direction={'row-reverse'}>
          <PrintButtonRound size={24} onClick={handlePrint} />
          <MarginBox mr={20} />
          {menu}
        </Flex>
      </Flex>
      <Box height={30} />
      <Pipeline horizontal size={'100%'} />
    </MarginBox>
  );
};
export default MaintenanceHeader;
