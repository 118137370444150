import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { MaintenanceDataProps } from 'pages/CatalogPage/DH/Maintenance/Maintenance';
import { MilageIntervalTable } from 'pages/CatalogPage/DH/Maintenance/Maintenance.styled';
import { Box, CenterFlex, Flex, Text } from 'UI';
import { textFormatter } from 'utils';

const Title = ({ children }: { children: string }) => (
  <Text type={'h6'} disableGutter transform={'capitalize'}>
    {children}
  </Text>
);

export const formatYear = (year: string) => {
  const formattedYear = textFormatter.formatNumber(Number(year), 1);
  if (formattedYear === '0' || formattedYear === '') return '.';
  return formattedYear;
};

const getColumns = (getLabel: (id: number) => string, t: TFunction) => {
  return [
    {
      title: <Title>{t('catalog.maintenance.operations', 'Operations')}</Title>,
      children: [
        {
          dataIndex: 'label',
          key: 'label',
          colspan: 2,
          className: 'whiteBg',
        },
        {
          dataIndex: 'label2',
          key: 'label2',
          colspan: 0,
          className: 'whiteBg',
        },
      ],
    },
    {
      title: <Title>{getLabel(50)}</Title>,
      children: [
        {
          title: <Title>{getLabel(16)}</Title>,
          dataIndex: 'fromKm',
          key: 'fromKm',
          width: 200,
          render: function renderFromKm(fromKm: number) {
            return <CenterFlex>{fromKm || '.'}</CenterFlex>;
          },
          className: 'whiteBg',
        },
        {
          title: <Title>{getLabel(10)}</Title>,
          dataIndex: 'fromYears',
          key: 'fromYears',
          width: 200,
          render: function renderFromKm(fromYears: string) {
            return <CenterFlex>{formatYear(fromYears)}</CenterFlex>;
          },
          className: 'whiteBg',
        },
      ],
    },
    {
      title: <Title>{`${getLabel(51)} ${getLabel(40)}`}</Title>,
      children: [
        {
          title: <Title>{getLabel(16)}</Title>,
          dataIndex: 'everyKm',
          key: 'everyKm',
          width: 200,
          render: function renderFromKm(everyKm: number) {
            return <CenterFlex>{everyKm || '.'}</CenterFlex>;
          },
          className: 'whiteBg',
        },
        {
          title: <Title>{getLabel(10)}</Title>,
          dataIndex: 'everyYears',
          key: 'everyYears',
          width: 200,
          render: function renderFromKm(everyYears: string) {
            return <CenterFlex>{formatYear(everyYears)}</CenterFlex>;
          },
          className: 'whiteBg',
        },
      ],
    },
  ];
};

const MileageTimeSection = ({ maintenancePlanData, getLabel }: MaintenanceDataProps) => {
  const { t } = useTranslation();
  if (maintenancePlanData.operationByMileageOrTimeList.length === 0) return <></>;
  const columns = getColumns(getLabel, t);

  return (
    <>
      <Flex>
        <Flex direction={'column'}>
          <Text type={'h5_bold'}>{getLabel(27)}</Text>
          <Box height={17} />
          <Text type={'text_dim'}>{getLabel(26)}</Text>
        </Flex>
      </Flex>
      <Box height={33} />
      <MilageIntervalTable
        rowKey={'id'}
        columns={columns}
        dataSource={maintenancePlanData.operationByMileageOrTimeList}
        pagination={false}
      />
    </>
  );
};

export default MileageTimeSection;
